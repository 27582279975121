//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* ---------------------------------------------------------------
Naviplus Header Search (旧src/scss/pc/serch_top.scss)
--------------------------------------------------------------- */
.header {
  .side__megamenuTypeColor_ {
    float: left;
    overflow: hidden;
    width: 80px;
    height: auto;
    margin: 0 50px 0 0;
    padding: 0 50px 0 0;
    border-right: #c9e6f8 solid 1px;
  }
}

.findGkeywords {
  width: inherit;
  margin: -16px auto 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 16px 10px 0;

    a {
      font-size: 1.6rem;
      font-weight: normal;
      text-decoration: underline;
      color: $zoffBlue;
    }
  }
}

#search_suggest_area_ {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 130px;
  left: 50%;
  z-index: 1000;
  width: 100%;
  max-width: 660px;
  margin-left: -332px;
  padding: 0;
  text-align: left;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  background-color: #ffffff;

}

.suggest_keyword_ {
  padding: 2px;
}

.suggest_keyword_entry_ {
  display: block;
  text-decoration: none;
  color: #000;
}

.suggest_goods_ {
  display: block;
  position: relative;
  clear: both;
  width: 100%;
  min-height: auto;
  padding: 0;

  a {
    box-sizing: border-box;
    margin-left: 0;
    padding: 10px 20px;
  }
}

.suggest_goods_name_,
.suggest_goods_comment_,
.suggest_goods_price_ {
  display: inline-block;
  width: 300px;
  margin-left: 10px;
}

.suggest_goods_name_ {
  width: 100%;
}

.suggest_keyword_,
.suggest_goods_ {
  &:hover {
    background-color: #dedede;
    cursor: pointer;
    cursor: hand;
  }
}

.header {
  .header__navKeywordsSearchForm_ {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
  }

  .header__navKeywordsSearchFormInner_ {
    position: relative;
    width: 100%;
    height: 45px;
    margin: 0;
    font-size: 0;
    text-align: center;
  }

  .header__navKeywordsSearchText_ {
    display: inline-block;
    position: relative;
    width: 660px;
    margin: 80px 0 0;
    padding: .5em 40px .5em 60px;
    border: 0;
    border-bottom: $zoffBlue solid 2px;
    border-radius: 0;
    font-size: 1.6rem;
    line-height: 1;
    color: $zoffBlue;
    text-align: left;
  }

  .header__navKeywordsSearchTitle_ {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 97px;
    z-index: 5;
    width: auto;
    margin-left: -330px;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    font-weight: unset;
  }

  .header__navKeywordsSearchBtn_ {
    display: inline-block;
    position: relative;
    width: 18px;
    margin: 80px 0 0 -30px;
    padding: .5em;
    border-radius: 0;
    font-size: 1.6rem;
    line-height: 1;
    color: $zoffBlue;
  }

  .header__navKeywordsSearchClsBtn_ {
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    width: 120px;
    height: 50px;
    border: solid 1px $zoffBlue;

    &:after {
      content: '閉じる';
      display: block;
      position: absolute;
      left: 45px;
      top: 0;
      font-size: 1.6rem;
      line-height: 52px;
      color: $zoffBlue;
    }

    span {
      display: block;
      position: absolute;
      top: 50%;
      left: 12px;
      width: 26px;
      height: 2px;
      background: $zoffBlue;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }

  //----------- 検索モーダル内リンクリスト -----------

  .side__megamenu_ {
    margin: 60px 60px 0 60px;
    text-align: center;
  }

  .side__megamenuInner_ {
    display: flex;
    justify-content: center;
    min-width: 1140px;
    margin: 40px auto;
    padding: 0;
    text-align: left;
  }

  .side__megamenuTypeBox_,
  .side__megamenuGenreBox_ {
    box-sizing: border-box;
    width: 570px;
  }

  .side__megamenuTypeBox_ {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
  
    h4 {
      margin: 14px 0;
    }
  }
  
  .side__megamenuGenreBox_ {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    padding-left: 60px;
  }

  .side__megamenuTypeShape_ {
    margin: 0 50px 0 0;

    h4 {
      a {
        color: $zoffBlue;
      }
    }

    li {
      margin: 0 0 5px;

      a {
        position: relative;
        padding: 0 0 0 60px;
        text-align: left;
        line-height: 1;
        color: $zoffBlue;
      }

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 45px;
        height: 17px;
        background-size: cover;
        transform: translateY(-50%);
      }
    }

    .item__generalLink_ {
      a {
        padding-left: 0;
      }
    }
  }

  .side__megamenuTypeColor_ {
    overflow: hidden;
    width: 80px;
    height: auto;
    margin: 0 0px 0 0;
    padding: 0 50px 0 0;
    border-right: #c9e6f8 solid 1px;
    box-sizing: content-box;

    h4 {
      a {
        color: $zoffBlue;
      }
    }

    li {
      float: left;
      width: 20px;
      height: 20px;
      margin: 0 20px 10px 0;

      a {
        display: block;
        overflow: hidden;
        width: 20px;
        height: 20px;
        border-radius: 100%;
      }

      span {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  .side__megamenuTypePrice_ {
    height: auto;
    margin: 0 60px 0 0;
    padding: 0;

    h4 {
      a {
        color: $zoffBlue;
      }
    }

    li {
      margin: 0 0 10px;

      a {
        display: block;
        color: $zoffBlue;
      }
    }
  }

  .side__megamenuGenre_ {
    position: relative;
    margin: 0 40px 0 0;

    &:last-child {
      min-width: 250px;
      max-width: 350px;
      height: auto;
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }

    h4 {
      margin: 14px 0;
    }

    li {
      margin: 0 0 5px;
    }

    a {
      position: relative;
      text-align: left;
      color: $zoffBlue;
    }

    .ly__styleGuide__navSearchSeriesList--bottomSpace {
      margin-bottom: 20px;
    }
  }
  
  .side__megamenuSeriesBox_ {
    overflow: hidden;
  }

  .side__megamenuSeries_ {
    position: relative;
    height: auto;
    margin: 0 20px 0 0;

    h4 {
      margin: 14px 0;
    }

    li {
      margin: 0 0 5px;
    }

    a {
      position: relative;
      text-align: left;
      color: $zoffBlue;

      span {
        &.side__megamenuSummaryTxt_ {
          display: block;
          position: static;
          width: auto;
          height: auto;
          line-height: 140%;
          margin-bottom: 10px;
          margin-left: 0px;
          font-size: 1.2rem;
          color: $zoffBlue;
        }
      }
    }
  }

  .ly__styleGuide__navSearchTypeShapeList--topSpace {
    margin-top: 15px;
  }

}

/* ---------------------------------------------------------------
Shape / Color Icon
--------------------------------------------------------------- */
// [ Shape Icon ]
.item__shape_1_ {
  span {
    background-image: url(/img/private/pc/common/shape_square.png);
    background-repeat: no-repeat;
  }
}

.item__shape_2_ {
  span {
    background-image: url(/img/private/pc/common/shape_oval.png);
    background-repeat: no-repeat;
  }
}

.item__shape_3_ {
  span {
    background-image: url(/img/private/pc/common/shape_wellington.png);
    background-repeat: no-repeat;
  }
}

.item__shape_4_ {
  span {
    background-image: url(/img/private/pc/common/shape_boston.png);
    background-repeat: no-repeat;
  }
}

.item__shape_5_ {
  span {
    background-image: url(/img/private/pc/common/shape_round.png);
    background-repeat: no-repeat;
  }
}

// [ Color Icon ]
.item__color__1_ {
  span {
    background: #000;
  }
}

.item__color__2_ {
  span {
    background: #999;
  }
}

.item__color__3_ {
  span {
    background: #960;
  }
}

.item__color__4_ {
  span {
    background: #060;
  }
}

.item__color__5_ {
  span {
    background: #039;
  }
}

.item__color__6_ {
  span {
    background: #639;
  }
}

.item__color__7_ {
  span {
    background: #fc0;
  }
}

.item__color__8_ {
  span {
    background: #f6b1d8;
  }
}

.item__color__9_ {
  span {
    background: #c00;
  }
}

.item__color__10_ {
  span {
    background: #f60;
  }
}

.item__color__11_ {
  span {
    background: url(/img/private/pc/common/color_silver.png) 50% 50%;
    background-size: 100%;
  }
}

.item__color__12_ {
  span {
    background: url(/img/private/pc/common/color_gold.png) 50% 50%;
    background-size: 100%;
  }
}

.item__color__13_ {
  span {
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border: #ccc solid 1px;
    border-radius: 100%;
    background: #FFF;
  }
}

.item__color__99_ {
  span {
    background: url(/img/private/pc/common/color_other.png) 50% 50%;
    background-size: 100%;
  }
}

.ly__styleGuide__navSearchList--topSpace {
  margin-bottom: 20px;
}

.ui-helper-clearfix {

  &::before,
  &::after {
    content: "";
    display: table;
    border-collapse: collapse;
  }

  &::after {
    clear: both;
  }
}

.header__navKeywordsSearchFormInner_ {
  input[type="text"] {
    &:focus {
      border: none;
      border-bottom: $zoffBlue solid 2px;
    }
  }
}

.header__navKeywordsLink {
  width: 600px;
  margin-top: 120px;
  margin-right: auto;
  margin-left: auto;
}

.sizeSuggest__titleModal {
  span {
    color: $zoffBlue;
  }
}
