//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* ---------------------------------------------------------------
stylegide common
--------------------------------------------------------------- */
html {
  //background-color: $colorAreaGray;
}
body {
  font-family: $font-ja;
  line-height: 1.5;
  text-align: left;
  min-width: 768px;
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
}

h1 {
  font-size: 4rem;
  line-height: 1;
}

h2 {
  font-size: 2.8rem;
  line-height: 1;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.4rem;
}

@media all and (-ms-high-contrast: none) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
}

label {
  margin: 0;
  padding-bottom: 0;
  line-height: inherit;
  cursor: pointer;
  box-sizing: content-box;
  
  &.radio__label_{
    &::before{
      box-sizing: content-box
    }
  }
}

input[type="radio"] {
  display: none;
  width: 20px;
  height: 20px;
  box-shadow: none;

  +label {
    position: relative;
    padding-left: 30px;

    .radio__overall_ &{ // レンズ選択画面 override@/css/private/pc/user.css
      position: absolute;
      padding-left: 0;
      &::before,&::after{
        display: none;
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #757575;
      border-radius: 50%;
      background: #fff;
      box-sizing: content-box;
    }
  }

  &:checked {
    +label {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $zoffBlue;
        .radio__overall_ &,
        .formLayout__line3_ & { // レンズ選択画面 override@/css/private/pc/user.css
          display: none;
        }
      }
    }
  }
}

input[name="mailnews"] {
  +label {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid $colorDelimiterGray;
      border-radius: 50%;
      background: #fff;
    }
  }

  &:checked {
    +label {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $zoffBlue;
      }
    }
  }
}

input {
  &::placeholder {
    color: rgb(171, 211, 240);
  }
}

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  height: 50px;
  padding: 15px;
  border-radius: 0;
  font-size: 1.6rem;
}

input[type="text"]:focus,
input[type="search"]:focus,
input[type="password"]:focus {
  border: 1px solid $zoffBlue;
}

input,
select {
  &:focus {
    outline-offset: unset;
    outline: unset;
  }
}

textarea{
  border: 1px solid #2299e1;
  padding: 15px;
  border-radius: 0;
}



/* ---------------------------------------------------------------
wrapper
--------------------------------------------------------------- */
//※div.wrapper_ は /css/private/pc/user.css のオーバライド用
.wrapper_ ,div.wrapper_ {
  overflow: hidden;
  position: relative;
  left: 0;
  margin: 0;
  width: 100%;
  min-width: 768px;
}

/* ---------------------------------------------------------------
container
--------------------------------------------------------------- */

.container_ {
  overflow: hidden;
  position: relative;
  margin: 0 auto 80px;
  width: 100%;
  zoom: 1;

  th,
  td {
    border: none;
    font-weight: normal;
  }

  .submit_ {
    margin: 0 auto;
  }
}

.container__inner_ {
  width: 700px;
  margin: 0 auto;
}

/* --------------------------------------------------------------------
customer_status
--------------------------------------------------------------------- */
.customer_status_ {
  margin: 0;
  padding: 30px 0 5px;
  border-bottom: 1px solid #CCC;
}

/* ---------------------------------------------------------------
error
--------------------------------------------------------------- */
.error__top_ {
  margin-bottom: 30px;
  padding: 15px;
  border: $colorCaution solid 1px;
  font-weight: bold;
  color: $colorCaution;
}

.error_ {
  clear: both;
  width: auto;
  max-width: 700px;
  padding-top: 15px;
  border: none;
  font-weight: bold;
  color: $colorCaution;
}

/* --------------------------------------------------------------------
pageTitle
--------------------------------------------------------------------- */
.pageTitle_ {
  width: 700px;
  margin: auto;
  color: $zoffBlue;
  border-bottom: 1px solid $zoffBlue;
  padding-bottom: 20px;
  text-align: center;
  padding: 30px 0 20px;
}

.common_headline2_ {
  padding: 0;
  color: $zoffBlue;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

.styleGuide__pageTitle {
  padding: 30px 0 20px;
  border-bottom: 1px solid $zoffBlue;
  font-weight: normal;
  line-height: 1.4;
  text-align: center;
  color: $zoffBlue;
}

.ly__styleGuide__pageTitle {
  width: 700px;
  margin: auto;
}

/* --------------------------------------------------------------------
styleguide button
--------------------------------------------------------------------- */
button {
  position: relative;
  width: 100%;
  padding: 12px 5px;
  border: 1px solid $zoffBlue;
  font-size: 1.6rem;
  text-align: center;
  color: $zoffBlue;
  background: #fff;
  transition: .25s ease-out;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.btn__actFocusOff_ {
    &.on {
      border: 1px solid $colorAttention;
      color: #fff;
      background: $colorAttention;
      cursor: pointer;

    }
  }
}

.styleGuide__button {
  display: inline-block;
  box-sizing: border-box;
  min-width: 150px;
  padding: .7em 1.2em;
  text-align: center;
}

.ly__styleGuide__buttonBox {
  margin-bottom: 20px;
}

.ly__styleGuide__button--colum {
  margin: 0 10px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.styleGuide__button--base {
  color: #fff;
  background: $zoffBlue;
}

.styleGuide__button--frame {
  border: solid 1px $zoffBlue;
  color: $zoffBlue;
  background: #fff;
}

.input__redBtn_ {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 240px;
  height: 80px;
  margin-bottom: 10px;
  border: 1px solid $colorAttention;
  text-align: center;
  color: #fff;
  background: $colorAttention;
  transition: .25s ease-out;

  &:hover {
    opacity: .6;
  }

  >span {
    position: relative;
    width: 100%;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-20%, -50%);
      width: 24px;
      height: 20px;
    }
  }
}

.btn_innerTxt {
  position: absolute;
  top: 50%;
  right: 30%;
  transform: translate(30%, -50%);

  small {
    font-size: 1.1rem;
  }
}

.nextStep__fixBtnIn_ {
  button {
    width: 246px;
    padding: 6px 1em;
    font-size: 1.2rem;
  }
}

.termsDis {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 50px;
  padding: 16px 7px 14px;
  border: none;
  border-radius: 0;
  text-align: center;
  line-height: 1.3;
  color: #fff;
  background: #b7b7b7;
}

.index__function_ .swiper-slide,
.index__searchShop_ {
  img {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;
    border: solid 1px $colorLightGray;

  }
}

.form__btn_,
.submit__btn_ {
  border-radius: 0;
}

.form__btn_ {
  //width: 335px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid $zoffBlue;
    border-radius: 0;
    text-align: center;
    line-height: 1.3;

  }
  &:not(.btn__act_) a {
    color: $zoffBlue;
  }
}

.form__blueBtn_ {
  input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 335px;
    height: 50px;
    background-color: $zoffBlue;
    color: #fff;
    border: none;
    font-weight: normal;
    cursor: pointer;
  }
}

.btn__line1_ {
  .form__btn_ {
    margin: 0 auto;
  }
}

.form__midBtn_ {
  width: 335px;
}

/* ---------------------------------------------------------------
tax
--------------------------------------------------------------- */
.itemPrice_tax_ {

  :not(.page__lenschoice_){ // レンズ選択画面 override@/css/private/pc/user.css
    vertical-align: middle;
    margin-left: .2em;
    font-size: 1.56rem;
  }
}

/* --------------------------------------------------------------------
label
--------------------------------------------------------------------- */
.must_ {
  margin-left: 20px;
}

.ninni_ {
  margin-left: 20px;
}

.formRequired_ {
  margin-left: 20px;
  padding: 3px 10px;
  font-size: 1.6rem;
  color: #fff;
  background-color: $zoffBlue;
}

.formNoRequired_ {
  display: inline-block;
  vertical-align: text-top;
  margin-left: 10px;
  padding: 3px 10px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  background: #bfbfbf;
}

.del_ {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
}

.obiTxt_ {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin-bottom: 3px;
  margin-left: 5px;
  padding: 0 10px;
  font-size: 1.2rem;
  line-height: 1.4em;
  text-align: center;
  color: #fff;

  &:first-child {
    margin-left: 10px;
  }

  &.obi__1_ {
    background: $colorCaution;
  }

  &.obi__2_ {
    background: #7fccf0;
  }

  &.obi__3_ {
    background: #9bcb9b;
  }

  &.obi__4_ {
    background: #cc99cc;
  }

  &.obi__5_ {
    background: #99cdff;
  }
}

.obi__newOpen_ {
  background: $colorCaution;
}

.obi__renewal_ {
  background: #7fccf0;
}

.obi__closed_ {
  background: #999999;
}

.obi__taxFree_ {
  background: #ffd200;
}

.obi__srvTrans_ {
  background: #80c269;
}

.obi__srvSignLang_ {
  background: #8f82bc;
}

.obi__kidsSize {
  background: #57b3e8;
}

.obi__outlet_ {
  border: 1px solid $colorCaution;
  background: #fff;
  color: $colorCaution;
}

.obi__large {
  background: #80c269;
}

.obi__online_ {
  background: #fd9827;
}

.obi__frame_ {
  background: #e94a92;
}

.obi__lens_ {
  background: #e20c0c;
}

.obi__security_ {
  background: #89c551;
}

.obi__shop_ {
  background: #179be1;
}

.obi__price_ {
  background: #fdc504;
}

.obi__sungrass_ {
  background: #844d9e;
}

.obi__another_ {
  background: #2261e1;
}

.obi__order_ {
  background: #fd8b04;
}

.obi__delivery_ {
  background: #3d9015;
}

.obi__line_ {
  background: #08bf5b;
}

/* ---------------------------------------------------------------
badge
--------------------------------------------------------------- */
.category__icon_ {
  width: 26px;

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 30px;

    span {
      &.badge_count {
        position: absolute;
        top: -.5em;
        right: -.8em;
        padding: 0.3em .5em;
        border-radius: 1em;
        font-size: 1rem;
        line-height: 1;
        color: #fff;
        background: #f00;
      }
    }
  }
}

/* ---------------------------------------------------------------
parts
--------------------------------------------------------------- */
.small_ {
  font-size: 1.3rem;
}

.grayArea_ {
  box-sizing: border-box;
  background: $colorAreaGray;
}

.gheader_ {
  .header__navList_ {
    display: inline-block;
    position: relative;
    text-align: right;
    margin: 3px 0;
  }
}

.lead__nameBox_ {
  overflow: hidden;
  padding: 20px 0;
  border-bottom: 1px solid $colorDelimiterGray;
}

.hidden_ {
  visibility: hidden;
}

.submit__container_ {
  margin-top: 50px;
}

/* --------------------------------------------------------------------
form
--------------------------------------------------------------------- */
.table__block_ {
  width: 100%;
}

.formdetail_ {
  margin: 0 auto;

  &.form__box_{
    margin-bottom: 30px;
  }
}

.efo__wrapBox_ {
  position: relative;
}

.formContent_ {
  box-sizing: border-box;

  tr {
    display: block;
    padding: 25px 0 30px;
    border-bottom: 1px solid #ebebeb;
  }

  td {
    display: block;
  }

  .no__separate_ {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .fContent__layout__line2_ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .efo__wrapBox_ {
      display: block;
      flex-basis: 48%;
    }
  }

  .fContent__layout__line3_ {
    display: flex;
    justify-content: space-between;

    .fContent__parts__sizeS_ {
      display: flex;
      align-items: center;
      width: 100%;
    }

    label {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 18px;
        right: 1.5em;
        width: 10px;
        height: 10px;
        border-right: $zoffBlue solid 1px;
        border-bottom: $zoffBlue solid 1px;
        transform: rotate(45deg);
        pointer-events: none;
      }
    }

    select {
      width: 182px;
      margin: 5px 10px;
    }

    .fContent__select__wrap_ {
      &::after {
        display: none;
      }
    }
  }

  .small_ {
    margin-top: 10px;
  }
}

.fContent__title_ {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 2rem;
  line-height: 1;
  color: $zoffBlue;
}

.fContent__parts__sizeM_ {
  width: 44%;
}

.fContent__parts__sizeS_ {
  width: 26%;
}

.fContent__select__wrap_ {
  display: block;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 17px;
    right: 1em;
    width: 10px;
    height: 10px;
    border-right: $zoffBlue solid 1px;
    border-bottom: $zoffBlue solid 1px;
    transform: rotate(45deg);
    pointer-events: none;
  }

  select {
    display: block;
    box-sizing: border-box;
    position: relative;
    float: none;
    text-align: left;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: .5em .5em .5em 1em;
    border: $zoffBlue solid 1px;
    background: #fff;

  }
}

.plusmark_ {
  display: block;
  position: relative;
  margin-bottom: 0;
  padding: 15px 0 15px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 21px;
    height: 1px;
    background: $zoffBlue;
    transform: translateY(-50%) rotate(90deg);
    transition: .25s ease;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 21px;
    height: 1px;
    background: $zoffBlue;
    transition: .25s ease;
  }
}

/* --------------------------------------------------------------------
check box
--------------------------------------------------------------------- */
input[type="checkbox"] {
  position: relative;
  background: #fff;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  appearance: none;
}

.checkbox__parts_ {
  user-select: none;
}

.checkbox__label_ {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #757575;
    background: #f1f1f1;
    box-sizing: content-box;
  }
}

.one__goods_ {
  position: relative;
}

.termsCheck_label {
  position: relative;
  padding-left: 30px;
  user-select: none;
  cursor: pointer;

  a {
    text-decoration: underline;
    color: $zoffBlue;
  }
}

.termsCheck_check {
  display: none;

  +.termsCheck_label {
    &::before {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      border: 2px solid #666;
      background: #fff;
    }
  }

  &:checked {
    +.termsCheck_label {
      &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 50%;
        width: 15px;
        height: 8px;
        margin-top: -10px;
        border-bottom: 4px solid $zoffBlue;
        border-left: 4px solid $zoffBlue;
        transform: rotate(-45deg);
      }
    }
  }
}

.cpformContainer_form_contents_questionnaireList_contents {
  input[type="checkbox"] {
    display: none;

    +label {
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      padding: 10px 15px 10px 45px;
      border: 2px solid #ccc;
      user-select: none;

      &::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 15px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        border: 2px solid #757575;
        background: #fff;
      }
    }
  }
}

.cpformContainer {
  input[type="checkbox"] {
    &:checked {
      +label {
        &::after {
          content: "";
          position: absolute;
          left: 17px;
          top: 50%;
          width: 13px;
          height: 6px;
          margin-top: -8px;
          border-bottom: 4px solid $zoffBlue;
          border-left: 4px solid $zoffBlue;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.formLayout__lineFree_ {
  label {
    display: inline-block;
    padding-right: 30px;
  }
}

.checkbox__label_ {
  display: inline-block;
  position: relative;
  padding-left: 35px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #757575;
    background: #f1f1f1;
  }

  input {
    display: none;
  }
}

label {
  &.checkbox__label_ {
    input {
      &:checked {
        ~.checkbox__parts_ {
          &::before {
            content: "";
            width: 13px;
            height: 8px;
            display: block;
            border-top: #229ae1 solid 3px;
            border-right: #229ae1 solid 3px;
            box-sizing: content-box;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(135deg) translateY(-50%);
          }
        }
      }
    }
  }
}

/* --------------------------------------------------------------------
pagenation
--------------------------------------------------------------------- */
.pagenation_ {
  margin: 50px 0;
}

.navipage_ {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;

  &.top_ {
    margin: 25px 0;
    padding: 0;
    font-size: 2rem;
    text-align: left;
    color: $zoffBlue;
  }

  a {
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    color: #000;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1;
    min-width: 30px;
    padding: 9px 7px;
    margin: 0 5px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;

    &:hover {
      color: #fff;
      background: $zoffBlue;
      opacity: 1;
    }
  }

  .navipage_forward_ {
    margin-left: 5px;
  }
}

.navipage_reverse_ {
  margin-right: 5px;

  a {
    position: relative;
    font-size: 1.8rem;
  }

  .navipage_first_ {
    a {
      padding-left: 18px;
      font-size: 1.6rem;
      border: none;

      &:hover {
        background: transparent;
        color: #000;
        opacity: .7;
      }

      &::before {
        content: "";
        position: absolute;
        top: 14px;
        left: 0;
        width: 5px;
        height: 5px;
        border-bottom: solid 1px #000;
        border-left: solid 1px #000;
        transform: rotate(45deg);
      }

      &::after {
        content: "";
        position: absolute;
        top: 14px;
        left: 5px;
        width: 5px;
        height: 5px;
        border-bottom: #000 solid 1px;
        border-left: #000 solid 1px;
        transform: rotate(45deg);
      }
    }
  }
}

.navipage_prev_ {
  a {
    padding-left: 12px;
    font-size: 1.6rem;
    border: none;

    &:hover {
      background: transparent;
      color: #000;
      opacity: .7;
    }

    &::after {
      content: "";
      position: absolute;
      top: 14px;
      left: 0;
      width: 5px;
      height: 5px;
      border-bottom: #000 solid 1px;
      border-left: #000 solid 1px;
      transform: rotate(45deg);
    }
  }
}

.navipage_last_ {
  a {
    padding-right: 18px;

    &:hover {
      background: transparent;
      color: #000;
      opacity: .7;
    }
  }
}

.navipage_forward_ {
  a {
    position: relative;
    border: none;
    font-size: 1.6rem;
    color: #000;
  }

  .navipage_last_ {
    a {
      &::before {
        content: "";
        position: absolute;
        top: 14px;
        right: 0;
        width: 5px;
        height: 5px;
        border-top: #000 solid 1px;
        border-right: #000 solid 1px;
        transform: rotate(45deg);
      }

      &::after {
        content: "";
        position: absolute;
        top: 14px;
        right: 5px;
        width: 5px;
        height: 5px;
        border-top: #000 solid 1px;
        border-right: #000 solid 1px;
        transform: rotate(45deg);
      }
    }
  }
}

.navipage_now_ {
  color: $zoffBlue;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
  min-width: 30px;
  padding: 9px 7px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  margin: 0 5px;
}

.navipage_sum_ {
  strong {
    font-weight: normal;
  }
}

.navipage_next_ {
  a {
    padding-right: 12px;

    &::after {
      content: "";
      width: 5px;
      height: 5px;
      position: absolute;
      top: 14px;
      right: 0;
      border-top: #000 solid 1px;
      border-right: #000 solid 1px;
      transform: rotate(45deg);
    }

    &:hover {
      background: transparent;
      color: #000;
      opacity: .7;
    }
  }
}

/* --------------------------------------------------------------------
page__topBtn
--------------------------------------------------------------------- */
#page__topBtn { // トップ改修後に不要となる（～2024.02）
  display: block;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 12;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: #8bcdf0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 18px;
    left: 16px;
    width: 14px;
    height: 14px;
    border-top: #fff solid 1px;
    border-left: #fff solid 1px;
    transform: rotate(45deg);
  }
}

.page__topBtn { // トップ改修後のスタイル（2024.02～）
  &Wrapper{
    opacity: 0;
    pointer-events: none;
    @include transition(.4s,ease,0s);
    &.js-show{
      opacity: 1;
      pointer-events: all;
    }
  }
}
#page__topBtn {
  &.page__topBtn {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 12;
    width: 50px;
    height: 50px;
    background: transparent;
    @include transition(0s,linear,0s);
    &.js-absolute{
      position: absolute;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: #000;
      border-radius: 100%;
      @include transition(.4s,ease,0s);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 22px;
      left: 25px;
      width: 8px;
      height: 8px;
      border-top: #fff solid 1px;
      border-left: #fff solid 1px;
      transform: rotate(45deg);
      transform-origin: left top;
    }
    &:hover{
      opacity: 1;
      &::before {
        background-color: $zoffBlue;
      }
    }
  }
}


/* --------------------------------------------------------------------
Error
--------------------------------------------------------------------- */
.mainframe_{
  .error_.secureError{
    text-align: center;
    .centertext_{
      text-align: center;
    }
  }
}