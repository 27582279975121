//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================


/***
    The new CSS reset - version 1.11.1 (last updated 24.10.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" element is excluded, otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
 *:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Fix mobile Safari increase font-size on landscape mode */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu, summary {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  //max-inline-size: 100%;
  //max-block-size: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
  box-sizing: border-box;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
 display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
 - fix for the content editable attribute will work properly.
 - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}


/*
******************************************************************
reset
******************************************************************
*/
*{
  box-sizing: border-box;
  word-wrap: break-word;
}
html {
  font-size: 62.5%;
}

html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, address, caption, cite, code, dfn, em, strong, th, var, a, img {
  margin: 0;
  padding: 0;
  border: 0;
  font-style: normal;
}

body {
  min-width: 960px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: left;
  background: #fff;
}

address, caption, cite, code, dfn, em, th, var {
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

a {
  text-decoration: none;
  transition: .25s ease-out;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

img {
  vertical-align: bottom;
  border: none;
}

ol {
  li {
    margin-left: 2em;
    list-style: decimal;
  }
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

input {
  vertical-align: middle;
  padding: 1px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 1.6rem;

}

input[type="text"], input[type="search"], input[type="password"] {
  position: relative;
  height: 1.0em;
  margin: 5px 0;
  padding: 0.6em;
  border: 1px solid #999;
  border-radius: 5px;
}

input[type="password"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="image"], input[type="submit"], input[type="button"] {
  transition: .25s ease-out;

  &:hover {
    opacity: 0.7;
  }
}

input[type="text"], input[type="tel"], input[type="number"], input[type="email"], input[type="password"] {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 15px 7px;
  border: 1px solid $zoffBlue;
  border-radius: 0;
  line-height: 1.3;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input, button, textarea, select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid $colorLightGray;
}

q {

  &::before, ::after {
    content: '';
  }
}

::before, ::after {
  box-sizing: border-box;
}

/* to preserve line-height and selector appearance */
sup {
  position: relative;
  top: -0.1em;
  vertical-align: top;
  font-size: 75.5%;
}

sub {
  vertical-align: text-bottom;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}