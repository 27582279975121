//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* ---------------------------------------------------------------
Mixins
--------------------------------------------------------------- */
//デモグラメニュー：カレント表示用（$elem:対象クラス名）
@mixin navDemographicCurrent($elem){
  #{$elem} &{
    a{
      color: #fff;
      background-color: #000;
      opacity: 1;  
    }
    /* &::after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: solid 5px $zoffBlue;
      pointer-events: none;
    } */
  }
}

/* ---------------------------------------------------------------
header
--------------------------------------------------------------- */
//
$widthPCBase:1360;
$heightNaviBase:40;
//
.header{
  padding-bottom: 120px;
  background-color: #FFF;

  // [ Sub Navigation ]
  &__sub{
    background-color: $colorBg;
    padding: 0 20px;

    // [ Link Menu ]
    &Links{
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &Item{
        padding: 0 10px;
        a{
          font-size: 1.2rem;
          color: $colorText;
          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }

  // [ Inner ]
  &__inner{
    display: flex;
    align-items: center;
    background-color: rgba(#FFF,1);
  }

  // [ logo ]
  &__logo{
    img{
    }
  }

  // [ Navigation ]
  &__nav{
    display: flex;
    padding: 0 20px;

    // [ Demographic Menu ]
    &Demographic {
      display: flex;
      justify-content: center;
      background-color: $colorBg;
      border-radius: $heightNaviBase*1px;
      &Item {
        display: flex;
        align-items: center;
        a {
          @include font-en;
          font-size: 1.5rem;
          color: $colorText;
          display: flex;
          align-items: center;
          padding: 0 math.div(30,$widthPCBase)*100vw;
          border-radius: $heightNaviBase*1px;
          &:hover{
            color: #fff;
            background-color: #000;
            opacity: 1;
          }
          @media screen and (max-width: 1200px) {
            padding: 0 math.div(25,$widthPCBase)*100vw;
          }
          @media screen and (max-width: 1150px) {
            padding: 0 math.div(15,$widthPCBase)*100vw;
          }
          /* @media screen and (max-width: 990px) {
            font-size: math.div(16,$widthPCBase)*100vw;
          } */
          
          // [ Fixed Navigation ]
          .js-fixed &{
            padding: 0 math.div(30,$widthPCBase)*100vw;
            /* @media screen and (min-width: 901px) {
              padding: 0 math.div(30,$widthPCBase)*100vw;
            }
            @media screen and (min-width: 801px) and (max-width: 900px) {
              padding: 0 math.div(20,$widthPCBase)*100vw;
              font-size: math.div(20,$widthPCBase)*100vw;
            }
            @media screen and (max-width: 800px) {
              padding: 0 math.div(18,$widthPCBase)*100vw;
            } */
          }

        }

        &--women{
          @include navDemographicCurrent('.women');//カレント表示用（対象クラスを指定）
        }
        &--men{
          @include navDemographicCurrent('.men');//カレント表示用（対象クラスを指定）
        }
        &--kids{
          @include navDemographicCurrent('.kids');//カレント表示用（対象クラスを指定）
        }
        &--sunglasses{
          @include navDemographicCurrent('.sunglasses');//カレント表示用（対象クラスを指定）
        }
        &--outlet{
          a{
            color: #F53C3C;
          }
          @include navDemographicCurrent('.outlet');//カレント表示用（対象クラスを指定）
        }
  
      }
    }

    // [ Function Menu ]
    &Function{
      display: flex;
      align-items: center;
      margin-left: auto;

      &Item{
        a{
          color: $zoffBlue;
          font-size: 1.4rem;
          background-color: $colorBg;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $heightNaviBase*1px;

          &:hover{
            color: #fff;
            background-color: $zoffBlue;
            opacity: 1;
            svg{
              fill: #fff;
            }
            .header__navFunctionItemBtnIcon{
              background-color: $colorBg;
              color: $zoffBlue;
            }
          }
        }
        
        &--search{
          color: #F00!important;
          svg{
            width: 18px;
          }
        }
        &--mypage{
          svg{
            width: 18px;
          }
        }
        &--favorite{
          svg{
            width: 18px;
          }
        }
        &--cart{
          svg{
            width: 21px;
          }
        }
        &--login{
          padding: 0 20px;
        }
        svg{
          @include transition(.4s,ease,0s);
        }
        &Btn{
          position: relative;
          &Icon{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1em;
            padding: 0 0.2em;;
            font-size: 1rem;
            font-weight: $fwBold;
            line-height: 1;
            color: #fff;
            background: $zoffBlue;
            @include transition(.4s,ease,0s);
          }
        }
      }
    }

    // [ Store Seach Menu ]
    &StoreSheach{
      background-color: $zoffBlue;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      border-radius: $heightNaviBase*1px;
      &--text{
        font-size: 1.2rem;
        color: #fff;
      }
      &:hover{
        background-color: #000;
        opacity: 1;
        &--text{
          color: #fff;
        }
      }
    }

  }

}

//----------- Layout -----------
.l-{
  &header{
    width: 100%;
    min-width: 768px;
    position: relative;

/*     &::before{ //サブナビとの隙間埋め
      content: "";
      display: block;
      position: fixed;
      top: 0;
      z-index: 13;
      width: 100%;
      height: 40px;
      background-color: #FFF;
    } 
*/

    // [ Sub Navigation ]
    &__sub{
      min-height: 34px;
      position: relative;
      z-index: 14;
  
      // [ Link Menu ]
      &Links{
        &Item{
          a{
          }
        }
      }
    }

    &__inner{
      width: 100%;
      height: 120px;
      position: absolute;
      top: 34px;
      left: 0;  
      z-index: 15;
      @include transition(.0s,ease,0s);
      will-change: top;

      // [ Fixed Navigation ]
      .js-fixed &,
      .ecbn-selection-preview-overflow-hidden &{
        top: 0;
        height: 80px;
        position: fixed;
      }
    }
  
    // [ logo ]
    &__logo{
      position: absolute;
      left: 40px;
      top: 16px;
      max-width: 290px;
      width: math.div(290,$widthPCBase)*100vw;
      @include transition(.4s,ease,0s);
      img{
        width: inherit;
        height: auto;
      }
      @media screen and (max-width: 1220px) {
        width: math.div(300,$widthPCBase)*100vw;
      }
      @media screen and (max-width: 990px) {
        width: math.div(240,$widthPCBase)*100vw;
      }

      // [ Fixed Navigation ]
      .js-fixed &{
        top: 20px;
        width: 94px;
      }
    }
  
    // [ Navigation ]
    &__nav{
      width: 100%;

      // [ Demographic Menu ]
      &Demographic {
        //max-width: 576px;
        //width: math.div(576,$widthPCBase)*100vw;
        height: $heightNaviBase*1px;
        margin-left: auto;
        &Item {
          position: relative;
          a {
            width: 100%;
            height: 100%;
          }
        }
      }
  
      // [ Function Menu ]
      &Function{
        margin: 0 10px 0 20px;
        height: $heightNaviBase*1px;

        &Item{
          margin-right: 8px;
          width: $heightNaviBase*1px;
          height: inherit;
          &:last-child{
            margin-right: 0;
          }
          a{
            width: inherit;
            height: inherit;
          }
          &Btn{
            position: relative;
            &Icon{
              position: absolute;
              top: 0;
              right: 0;
              min-width: 18px;
              height: 18px;
            }
          }
        }
      }

      // [ Store Seach Menu ]
      &StoreSheach{
        width: 104px;
        height: $heightNaviBase*1px;
        svg{
          width: 18px;
          margin-right: 8px;
        }
      }

    }
  
  }
}
