//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* ---------------------------------------------------------------
Mixins
--------------------------------------------------------------- */

/* ---------------------------------------------------------------
footer
--------------------------------------------------------------- */
//
$widthPCBase:1360;
//
.footer{
  padding: 50px 0 20px;
  border-top: solid 1px $colorBorderGray;//区切りがわからないので一時的にボーダー追加

  // [ Inner ]
  &__inner{
    padding: 0 40px;
  }

  // [ logo ]
  &__logo{
    img{
    }
  }

  // [ Navigation ]
  &__nav{
    display: flex;

    &Group{}
    &Head{
      font-weight: $fwMid;
      font-size: 1.5rem;
      line-height: math.div(22,15);
      border-bottom: solid 1px $colorBorderGray;
    }
    &List{
      &Item{
        font-size: 1.5rem;
        line-height: math.div(22,15);
    
        a{
          color: $colorText;
          &:hover{
            color: $zoffBlue;
            opacity: 1;
            svg{
              fill: #fff;
            }
            .footer__navFunctionItemBtnIcon{
              background-color: $colorBg;
              color: $zoffBlue;
            }
          }
        }
        svg{
          @include transition(.4s,ease,0s);
        }
      }
    }

  }

  // [ Social ]
  &__social{
    display: flex;
    &Item{
      &Link{
        &:hover{
          opacity: 1;
          svg{
            fill: $zoffBlue;
          }
        }
      }
      svg{
        @include transition(.4s,ease,0s);
      }
    }
  }

  // [ Copyright ]
  &__copyright{
    font-size: 1.2rem;
    font-family: $font-en;
  }

}

//----------- Layout -----------
.l-{
  &footer{
    min-width: 768px;
    position: relative;

    &__inner{
    }
  
    // [ logo ]
    &__logo{
      width: 250px;
      margin-bottom: 50px;
      img{
        width: inherit;
        height: auto;
      }
    }
  
    // [ Navigation ]
    &__nav{
      margin-bottom: 90px;

      &Group{
        flex-basis: 180px;
        margin-left: math.div(60,$widthPCBase)*100%;
        &:first-child{
          margin-left: 0;
        }
      }
      &Head{
        padding-bottom: 14px;
        margin-bottom: 38px;
        white-space: nowrap;
      }
      &List{
        &Item{
          margin-bottom: 14px;
          a{
            &:hover{
              svg{
              }
              .footer__navFunctionItemBtnIcon{
              }
            }
          }
          svg{
            @include transition(.4s,ease,0s);
          }
        }
      }

    }

    // [ Social ]
    &__social{
      margin-bottom: 20px;
      &Item{
        margin-left: 24px;
        &:first-child{
          margin-left: 0;
        }
        &Link{}
        svg{
          height: 20px;
          width: auto;
        }
      }
    }

    // [ Copyright ]
    &__copyright{}

  }
}
